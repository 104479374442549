import { Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content'
import './diagnosis.css'
import { PageNav } from '../../components/page-nav/pageNav'

export const Diagnosis = () => {
  return (
    <AnimatedContent>
      <div className='page-content'>
        <div className='card-container'>
          <Card className='card' variant='outlined'>
            <CardHeader title='Diagnosis' subheader='Identify signs of SIJ' />
            <CardMedia
              component="img"
              image="/sij_diagnosis.jpg"
              alt="SIJ Pain"
              className="diag-card-media"
            />
            <CardContent className="card-description">
              <p className="background-text">
                Similar to diagnosing any condition, SIJD is best differentiated from other forms of pain through conducting a thorough history and physical exam of the patient. Specific tests can be done to narrow down the diagnosis of SIJD. The gold standard of diagnosis is invasive and involves administering nerve block injections though the SIJ by a medical professional. These injections can often be costly, pose risks such as infection, and are often difficult to administer (1). Trained clinicians may perform more conservative options such as motion palpation tests and provocation tests to gain valuable insights to the presence of SIJD without adding complexity.
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='SIJ Block Injections' />
            <CardMedia
              component="img"
              image="/sij8.png"
              alt="SIJ Pain"
              className="diag-card-media"
            />
            <CardContent className="card-description">
              <p className="background-text">
                The current gold standard of diagnostic tests for SIJ dysfunction is the SIJ block injection done under fluoroscopic guidance (2). This test injects an anesthetic, typically a corticosteroid, into the joint space and relies on an subjective report by the patient to determine whether or not the test is positive. Depending on the administrator of the injection, a decrease in pain between 60-75% is necessary for a positive diagnosis of SIJD (2,3).
                While the SIJ block is a reliable diagnostic test, it has its limitations. Studies show that corticosteroids may leak from the joint to nearby tissues, especially without imaging during the injection. This leakage can lead to pain relief being attributed to the surrounding tissues, potentially masking the true source of pain.
              </p>
              <p className="background-text">
                SIJ blocks pose higher risks, costs, and complexity due to their invasive nature, making them a last resort for diagnosing SIJ issues. Instead, trained clinicians often use less invasive motion palpation and provocative tests for a more conservative approach in identifying SIJD (1).
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='Motion Palpation Tests' />
            <CardContent className="card-description">
              <p className="background-text">
                The Gillet test and the forward flexion test are two motion palpation tests that observe the quality of movement and aim to identify any abnormalities or restrictions in the joint's range of motion and give information on a person’s ability to stabilize intrapelvic motion.
                Disclaimer: These tests are NOT diagnostic. These tests give insight into the movement of the joint that can further be used in clinical decision making.
                The Gillet and Forward flexion tests have a sensitivity of 100% and a specificity of 0%, indicating inability to correctly identify the cause of possible positive results (2).
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/IE34vC7fhw8?si=7LVlCDDAeSwHDWPO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Gillet Test (2)
              </h4>
              <p className='background-text'>
                To perform the Gillet Test:
                <ol>
                  <li>
                    Begin with the patient standing
                  </li>
                  <li>
                    The tester must palpate the S2 tubercle and the PSIS of the desired side
                  </li>
                  <li>
                    While palpating, have the patient flex the desired hip to a 90/90 position
                  </li>
                  <li>
                    Observe the motion of the PSIS relative to S2
                  </li>
                </ol>
                A negative test would observe a relative inferior movement of the PSIS with hip flexion.
                A positive test is indicated when the PSIS, on the side of hip flexion, moves relatively superiorly, or appears level with the S2 tubercle.
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/x6QzLvlnGhs?si=OviRj_6VpPZ8w82A" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Forward Flexion test (2)
              </h4>
              <p className='background-text'>
                To perform the Forward Flexion Test:
                <ol>
                  <li>
                    Begin with the patient standing
                  </li>
                  <li>
                    The tester must palpate bilateral inferior notch of PSIS
                  </li>
                  <li>
                    While palpating the PSIS, have the patient bend forward while maintaining fully extended knees
                  </li>
                  <li>
                    Observe the motion of the PSIS
                  </li>
                </ol>
                A negative test would observe equal superior movement with trunk and hip flexion.
                A positive test is indicated by a greater superior excursion of one PSIS over the other.
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='Provocative tests' />
            <CardContent className="card-description">
              <p className="background-text">
                Provocative tests are a diagnostic examination that aim to provoke symptoms associated with the SIJ. These tests are designed to assess the integrity and function of the SIJ by applying specific movements, pressures, or positions that may reveal signs of dysfunction.
              </p>
              {/* <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/OUwzaMVgGMk?si=s1bqeMC0V_RmDXO6" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div> */}
              <h4 className='background-text'>
                The Laslett Cluster
              </h4>
              <p className='background-text'>
                The Laslett cluster is a set of physical tests that plays a pivotal role in assessing the integrity of the SIJ and provoking symptoms in patients. This Cluster is made of six distinct examinations—compression, distraction, thigh thrust, sacral thrust, and Gaenslen's (performed on each side of the body)—this cluster is designed to differentiate SIJ involvement (4).
                A significant aspect of the Laslett cluster is its diagnostic criteria, requiring two of four Laslett tests, specifically between the distraction, compression, thigh thrust, or sacral thrust tests, performed to be positive. With these criteria, the cluster has significant predictive powers for SIJ involvement. Similarly, when three of the total six tests are found to be positive, the cluster of tests have comparable predictive powers of SIJD to the SIJ block injections. If all six tests yield negative results, SIJ involvement is typically ruled out, offering valuable insights into the complexity of diagnosing sacroiliac joint dysfunction (4).
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/OUwzaMVgGMk?si=s1bqeMC0V_RmDXO6" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Distraction Test
              </h4>
              <p className='background-text'>
                To perform this test:
                <ol>
                  <li>
                    Have the patient begin lying on their back with legs extended
                  </li>
                  <li>
                    The tester applies 3-6 lateral compressions on the ASIS with increasing force
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pWjvrhWMR4w?si=mmZnQCoWslv6k2nA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Compression Test
              </h4>
              <p className='background-text'>
                To perform this test:
                <ol>
                  <li>
                    Have the patient begin lying comfortably on their asymptomatic side
                  </li>
                  <li>
                    The tester applies 3-6 dorsal-lateral compressions over the iliac crest with increasing force
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/id4GqQ82BiM?si=lchBAGRKecqvhzY9" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Thigh Thrust Test
              </h4>
              <p className='background-text'>
                To perform this test:
                <ol>
                  <li>
                    Have the patient lie supine and the tester stands on the asymptomatic side.
                  </li>
                  <li>
                    Flex the leg on the symptomatic side to 90° of hip flexion and place your hand over the patient’s sacrum.
                  </li>
                  <li>
                    The tester then applies a longitudinal pressure through the patient’s femur, which will create a shearing force at the SI joint
                  </li>
                  <li>
                    The tester applies 3-6 thrusts with gradually increasing pressure.
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/7rxOWC9qoOA?si=imnJSvq4FfNWLZwt" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Sacral Thrust Test
              </h4>
              <p className='background-text'>
                To perform this test:
                <ol>
                  <li>
                    Begin by having the patient lie prone.
                  </li>
                  <li>
                    The tester will apply 3-6 thrusts anteriorly on the sacral spine with increasing pressure.
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ED_bYoQk9d4?si=QptydgzBc8KdNt7Q" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                Gaenslen’s Test (Counts as two provocative tests)
              </h4>
              <p className='background-text'>
                To perform this test:
                <ol>
                  <li>
                    Begin by having the patient lie supine by the edge of the table closest to the tested side
                  </li>
                  <li>
                    Have the patient lower the tested side off of the edge of the table and flex the knee of the other leg towards the chest
                  </li>
                  <li>
                    The tester then provides firm downward pressure to the leg off of the table, and superior pressure to the leg flexed towards the chest to provide a shearing force.
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms
              </p>

              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/nFza4MJv2Uo?si=J5gW_jKn_qfS6HZ1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <h4 className='background-text'>
                The FABER Test (4)
              </h4>
              <p className='background-text'>
                Another physical test not included in the Laslett cluster is Patrick’s test or the FABER (flexion, abduction, external rotation) test. Predictive values of this test had variable results, but overall, this test was the most specific amongst itself and the Laslett tests. It’s been found that a combination of thigh thrust and FABER denotes the most accurate results in predicting SIJ dysfunction than any other combination. This group of physical tests may be the best noninvasive measure of predicting the presence of SIJD with the current evidence.
                <br /><br />
                To perform this test:
                <ol>
                  <li>
                    Begin with the patient lying supine
                  </li>
                  <li>
                    Cross one leg with the ankle resting on the opposite leg’s upper thigh
                  </li>
                  <li>
                    The tester will stabilize the opposite ASIS while providing a posterior pressure to the ipsilateral knee until end range
                  </li>
                </ol>
                A positive test is indicated by a reproduction of patient’s symptoms or with limited range of motion
              </p>
            </CardContent>
          </Card>
        </div>
        <div className='ref-area'>
          <h3 className='ref-title'>
            References:
          </h3>
          <p>
            <ol>
              <li>
                Buchanan P, Vodapally S, Lee DW, et al. Successful diagnosis of sacroiliac joint dysfunction. Journal of Pain Research. 2021;Volume 14:3135-3143. doi:10.2147/jpr.s327351
              </li>
              <li>
                Nejati P, Sartaj E, Imani F, Moeineddin R, Nejati L, Safavi M. Accuracy of the diagnostic tests of sacroiliac joint dysfunction. Journal of Chiropractic Medicine. 2020;19(1):28-37. doi:10.1016/j.jcm.2019.12.002
              </li>
              <li>
                Simopoulos TT, Manchikanti L, Gupta S, et al. Systematic Review of the Diagnostic Accuracy and Therapeutic Effectiveness of Sacroiliac Joint Interventions. Pain Physician. 2015;18(5):E713-E756.
              </li>
              <li>
                Laslett M, Aprill CN, McDonald B, Young SB. Diagnosis of sacroiliac joint pain: Validity of individual provocation tests and composites of tests. Manual Therapy. 2005;10(3):207-218. doi:10.1016/j.math.2005.01.003
              </li>
            </ol>
          </p>
        </div>
      </div>
      <PageNav page='Diagnosis' />
    </AnimatedContent>
  )
}
