import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content'
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material'
import './pathology.css'
import { PageNav } from '../../components/page-nav/pageNav'

export const Pathology = () => {
    return (
        <AnimatedContent>
            <div className='page-content'>
                <div className='card-container'>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Pathology' subheader='What can cause SIJD?' />
                        <CardMedia
                            component="img"
                            image="/diagnosis.png"
                            alt="SIJ Pain"
                            className="diag-card-media"
                        />
                        <CardContent className="card-description">
                            <div className='pathology-content'>
                                <p className='pathology-text'>
                                    A number of risk factors can contribute to a diagnosis of SIJD this includes but are not limited to these common causes:
                                </p>
                                <ol>
                                    <li>
                                        <b>Trauma or Injury:</b> Accidents, falls, or injuries that affect the sacroiliac joint can lead to dysfunction. The SIJ is a strong and relatively stable joint, but traumatic events can disrupt its normal function. Here are some ways trauma can contribute to SIJD:
                                        <ol className='ordered-letters'>
                                            <li>
                                                Injury to Ligaments: Trauma can lead to stretching or tearing of the ligaments that support the SIJ. This can result in instability and altered joint mechanics.
                                            </li>
                                            <li>
                                                Impact on Joint Surfaces: Direct impact or forceful movements can cause damage to the articular surfaces of the SIJ, leading to inflammation and pain.
                                            </li>
                                            <li>
                                                Misalignment: Trauma may cause the bones of the sacrum and ilium to shift out of their normal alignment, affecting the proper functioning of the joint.
                                            </li>
                                            <li>
                                                Muscle Imbalances: In response to trauma, muscles around the SIJ may tighten or weaken, disrupting the balance needed for joint stability.
                                            </li>
                                            <li>
                                                Fractures or Dislocations: Severe trauma, such as fractures or dislocations involving the pelvic bones or sacrum, can directly impact the SIJ and contribute to dysfunction (2).
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Pregnancy:</b> Changes in hormone levels and increased joint mobility during pregnancy can contribute to SIJD. SIJD can occur during pregnancy for a number of reasons: increased weight, change in posture, increased abdominal and intrauterine pressure, and laxity of the spine and pelvic structures all contribute to increasing force on the pelvis. Not only this but release of certain hormones such as relaxin and estrogen may cause laxity to the extent of symphysiolysis- or separation of the pubic symphysis joint (1).
                                    </li>
                                    <li>
                                        <b>Arthritis:</b> Inflammatory conditions like arthritis can affect the sacroiliac joint, causing pain and dysfunction. Typical of all osteoarthritic joints, joint space narrowing, osteophyte formation, and sclerosis of the SI joint can be seen on x-ray. Furthermore, inflammatory arthritis can cause SI joint injury. Ankylosing spondylitis is the most common type of inflammatory arthritis of the SI joint. Erosion of the joint can occur over time, leading to disabling pain and systemic inflammation. Over time the joint may fuse as erosion occurs over the whole joint (2).
                                    </li>
                                    <li>
                                        <b>Degenerative Changes:</b> Wear and tear over time, especially with age, can lead to degeneration of the sacroiliac joint and have similar effects as arthritis (3).
                                    </li>
                                    <li>
                                        <b>Anatomical Variances:</b> Structural differences or abnormalities in the sacroiliac joint region may predispose individuals to SIJD, although this cannot conclusively be proven (4).
                                    </li>
                                    <li>
                                        <b>Muscle Imbalances:</b> Weakness or tightness in muscles around the pelvis can affect the stability of the sacroiliac joint. Tonicity or weakness in attaching muscles can contribute to biomechanical dysfunction, and inappropriate attenuation of forces and shock, contributing to sacroiliac dysfunction (5).
                                    </li>
                                </ol>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div className='ref-area'>
                    <h3 className='ref-title'>
                        References:
                    </h3>
                    <p>
                        <ol>
                            <li>
                                Fiani B, Sekhon M, Doan T, et al. Sacroiliac Joint and Pelvic Dysfunction Due to Symphysiolysis in Postpartum Women. Cureus. 2021;13(10):e18619. Published 2021 Oct 9. doi:10.7759/cureus.18619
                            </li>
                            <li>
                                Dydyk AM, Forro SD, Hanna A. Sacroiliac Joint Injury. [Updated 2023 Jul 4]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2024 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK557881/
                            </li>
                            <li>
                                Yaichiro Okuzu, Koji Goto, Yu Shimizu, Toshiyuki Kawai, Yutaka Kuroda, Shuichi Matsuda, Sacroiliac joint degeneration is common in patients with end-stage hip osteoarthritis secondary to unilateral developmental dysplasia of the hip: Factors associated with its severity and laterality, Journal of Orthopaedic Science, Volume 26, Issue 1, 2021, Pages 135-140, ISSN 0949-2658, https://doi.org/10.1016/j.jos.2020.02.005.
                            </li>
                            <li>
                                Vereecke E, Morbée L, Laloo F, et al. Anatomical variation of the sacroiliac joints: an MRI study with synthetic CT images. Insights Imaging. 2023;14(1):30. Published 2023 Feb 8. doi:10.1186/s13244-023-01373-1
                            </li>
                            <li>
                                Wong M, Sinkler MA, Kiel J. Anatomy, Abdomen and Pelvis, Sacroiliac Joint. [Updated 2023 Aug 8]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2024 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK507801/
                            </li>
                        </ol>
                    </p>
                </div>
            </div>
            <PageNav page='Pathology' />
        </AnimatedContent>
    )
}
