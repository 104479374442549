import React from 'react'
import './navBar.css'
import { Box, Button, Menu, MenuItem, Link } from '@mui/material'
import { Dropdown, MenuButton } from '@mui/base';
import { Link as RouterLink, To, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const NavBar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElxl, setAnchorElxl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openxl = Boolean(anchorElxl);

    const navigateTo = (url: To) => {
        navigate(url);
        setAnchorEl(null)
    }

    const navigateToXl = (url: To) => {
        navigate(url);
        setAnchorElxl(null)
    }

    const menuItemActive = (url: String) => {
        if (location.pathname == url) {
            return 'navy-blue'
        } else {
            return 'undefined'
        }
    }

    return (
        <div className='header-container'>
            <div className='header-conditions'>
                <Box className='header-items' sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex' }
                }}>
                    <Link component={RouterLink} underline='none' to={'/home'} className='header-item' fontSize={20}>
                        <p className={`link-text ${menuItemActive('/home')}`}>Home</p></Link>
                    <Link component={RouterLink} underline='none' to={'/anatomy'} className='header-item' fontSize={20}
                    ><p className={`link-text ${menuItemActive('/anatomy')}`}>Anatomy</p></Link>
                    <Link component={RouterLink} underline='none' to={'/pathology'} className='header-item' fontSize={20}
                    ><p className={`link-text ${menuItemActive('/pathology')}`}>Pathology</p></Link>
                    <Link component={RouterLink} underline='none' to={'/diagnosis'} className='header-item' fontSize={20}
                    ><p className={`link-text ${menuItemActive('/diagnosis')}`}>Diagnosis</p></Link>
                    <Link component={RouterLink} underline='none' to={'/non-invasive-treatment'} className='header-item' fontSize={20}
                    ><p className={`link-text ${menuItemActive('/non-invasive-treatment')}`}>Non-Invasive Treatment</p></Link>
                    <Link component={RouterLink} underline='none' to={'/invasive-treatment'} className='header-item' fontSize={20}
                    ><p className={`link-text ${menuItemActive('/invasive-treatment')}`}>Invasive Treatment</p></Link>
                </Box>
                <Box sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                    justifyContent: 'right',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        sx={{
                            marginRight: '1rem'
                        }}
                        className='dropdown-btn'
                    >
                        <MenuIcon fontSize='large' className='dropdown-icon' />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/home') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/home') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/home')}
                        >Home</MenuItem>
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/anatomy') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/anatomy') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/anatomy')}
                        >Anatomy</MenuItem>
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/pathology') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/pathology') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/pathology')}
                        >Pathology</MenuItem>
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/diagnosis') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/diagnosis') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/diagnosis')}
                        >Diagnosis</MenuItem>
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/non-invasive-treatment') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/non-invasive-treatment') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/non-invasive-treatment')}
                        >Non-Invasive Treatment</MenuItem>
                        <MenuItem sx={{
                            backgroundColor: menuItemActive('/invasive-treatment') == 'navy-blue' ? '#3e5075' : 'undefined',
                            color: menuItemActive('/invasive-treatment') == 'navy-blue' ? 'white' : 'undefined'
                        }}
                            onClick={() => navigateTo('/invasive-treatment')}
                        >Invasive Treatment</MenuItem>
                    </Menu>
                </Box>
            </div>
        </div >
    )
}
