import { Button, Card, CardContent, CardHeader, CardMedia } from '@mui/material'
import './background.css'
import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { PageNav } from '../../components/page-nav/pageNav';

export const Background = () => {
    const navigate = useNavigate()
    return (
        <AnimatedContent>
            <div className='page-content'>
                <div className='card-container'>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Description' subheader='Figure 1. (Location of the SIJ)' />
                        <CardMedia
                            component="img"
                            image="sij1.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className="background-text">The sacroiliac joint (SIJ) is formed by the space between the sacrum and two innominates of the pelvis. The SIJ is a diarthrodial joint that allows for rotation and translation. Its role is to distribute the forces and transfer loads from the lower extremities through the spine and the rest of the body (1).</p>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Joint' subheader='Figure 2. (Cross section of the joint)' />
                        <CardMedia
                            component="img"
                            image="sij2.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                The SIJ is a diarthrodial covered by a fibrous capsule and filled with synovial fluid. The sacral capsule is composed of hyaline cartilage, while the iliac capsule is composed of fibrocartilage, making this joint well fortified. These joint surfaces begin flat and allow for easy gliding between the bones, but as people age, the surfaces become uneven and rigid, allowing for interlocking of the sacrum and ilium. This limits the movement of the SIJ and subsequently increases the stability of the joint (2).
                            </p>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Ligaments' subheader='Figure 3. (Major ligaments supporting the SIJ)' />
                        <CardMedia
                            component="img"
                            image="sij3.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                A number of ligaments contribute to stability of the SIJ. The three following ligaments are significant contributors to supporting the joint (2):
                            </p>
                            <ul>
                                <li>
                                    <b>Interosseous Sacroiliac Ligament-</b> This ligament forms a major connection between the sacrum and the innominate as it is a strong, short ligament deep to the posterior sacroiliac ligament.  It resists anterior rotation (nutation) and inferior movement of the sacrum.
                                </li>
                                <li>
                                    <b>Posterior Sacroiliac Ligament-</b> This ligament connects the posterior-superior iliac spine (PSIS) and iliac crests to the third and fourth segments of the sacrum. This ligament is very strong and resists posterior sacral rotation (counter-nutation).
                                </li>
                                <li>
                                    <b>Anterior Sacroiliac Ligament-</b> This ligament runs along the inner iliac ala, on the anterior surface, and blends into the joint capsule of the sacrum. This ligament prevents anterior and inferior sacral movement. It is the thinnest of these ligamentous structures and most vulnerable to injury, making it a common source of pain.
                                </li>
                            </ul>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Sacrotuberous Ligament' subheader='Figure 4. (Sacrotuberous ligament)' />
                        <CardMedia
                            component="img"
                            image="sij4.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                This ligament is composed of three fibrous bands that run from the ischial tuberosity to the inferior border of the sacrum. The sacrotuberous ligament resists nutation of the sacrum (2).
                            </p>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Sacrospinous Ligament' subheader='Figure 5. (Sacrospinous Ligament)' />
                        <CardMedia
                            component="img"
                            image="sij5.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                This ligament originates on the ischial spine and attaches onto the lateral sacrum and opposes the nutation of the sacrum that occurs with weight bearing and gait (2).
                            </p>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Nerves' subheader='Figure 6. (SIJ Nerve Distribution)' />
                        <CardMedia
                            component="img"
                            image="sij6.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                The sacroiliac joint receives its innervation from the ventral rami of L4 and L5, superior gluteal nerve, and dorsal rami of L5-S2. Of note, the pudendal nerve lies between the sacrotuberous ligament and the sacrospinous ligament and may become entrapped, leading to perineal pain. This distribution may differ person to person and with dysfunction may lead to various pain presentations (1).
                            </p>
                            <p className='background-text'>
                                Referred pain maps from SIJD extend through this nerve distribution, and commonly present as pain in the buttocks, groin, posterior thigh, and lower leg with radicular symptoms. However, this pain distribution demonstrates extensive variability among patients and bears strong similarities to discogenic or facet joint sources of LBP (3).
                            </p>
                        </CardContent>
                    </Card>
                    <Card className='card' variant='outlined'>
                        <CardHeader title='Muscles' subheader='Figure 7. (Musculature connecting to the pelvic girdle)' />
                        <CardMedia
                            component="img"
                            image="sij7.png"
                            alt="SIJ Pain"
                            className="bg-card-media"
                        />
                        <CardContent className="card-description">
                            <p className='background-text'>
                                There are 35 muscles that attach onto the sacrum or innominates which mainly provide stability to the joint rather than producing movements. Tonicity or weakness in these muscles can contribute to biomechanical dysfunction contributing to sacroiliac dysfunction. Some notable muscular structures include (4):
                                <ul>
                                    <li>
                                        Iliopsoas
                                    </li>
                                    <li>
                                        Rectus femoris
                                    </li>
                                    <li>
                                        Gluteus medius
                                    </li>
                                    <li>
                                        Gluteus maximus
                                    </li>
                                    <li>
                                        Lower abdominal muscles
                                    </li>
                                    <li>
                                        Hamstrings
                                    </li>
                                    <li>
                                        Piriformis
                                    </li>
                                    <li>
                                        Tensor fascia latae
                                    </li>
                                    <li>
                                        Adductors
                                    </li>
                                    <li>
                                        Quadratus lumborum
                                    </li>
                                    <li>
                                        Latissimus dorsi
                                    </li>
                                    <li>
                                        Obturator internus
                                    </li>
                                </ul>
                            </p>
                        </CardContent>
                    </Card>
                </div>
                <div className='ref-area'>
                    <h3 className='ref-title'>
                        References:
                    </h3>
                    <p>
                        <ol>
                            <li>
                                Prather H, Bonnette M, Hunt D. Nonoperative Treatment Options for Patients With Sacroiliac Joint Pain. Int J Spine Surg. 2020;14(Suppl 1):35-40. Published 2020 Feb 10. doi:10.14444/6082
                            </li>
                            <li>
                                Wong M, Sinkler MA, Kiel J. Anatomy, Abdomen and Pelvis, Sacroiliac Joint. [Updated 2023 Aug 8]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2024 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK507801/
                            </li>
                            <li>
                                Barros G, McGrath L, Gelfenbeyn M. Sacroiliac Joint Dysfunction in Patients With Low Back Pain. Fed Pract. 2019;36(8):370-375.
                            </li>
                            <li>
                                Kiapour A, Joukar A, Elgafy H, Erbulut DU, Agarwal AK, Goel VK. Biomechanics of the sacroiliac joint: anatomy, function, biomechanics, sexual dimorphism, and causes of pain. International journal of spine surgery. 2020 Feb 1;14(s1):S3-13.
                            </li>
                        </ol>
                    </p>
                </div>
            </div>
            <PageNav page='Anatomy' />
        </AnimatedContent>
    )
}
