import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content'

export const NotFound = () => {
    return (
        <AnimatedContent>
            <div className='page-content'>NotFound</div>
        </AnimatedContent>
    )
}
