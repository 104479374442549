import { Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content'
import { PageNav } from '../../components/page-nav/pageNav'

export const ConservativeTreatment = () => {
  return (
    <AnimatedContent>
      <div className='page-content'>
        <div className='card-container'>
          <Card className='card' variant='outlined'>
            <CardHeader title='Non-Invasive Treatments' subheader='Treatment preventing condition from getting worse' />
            <CardMedia
              component="img"
              image="/exercise.png"
              alt="SIJ Pain"
              className="ct-card-media"
            />
            <CardContent className="card-description">
              <p className="background-text">
                Upon confirming a diagnosis of SIJD through Laslett provocation tests, the FABER test, or block injection testing, the focus can shift to implementing effective treatment strategies. Conservative approaches encompass the use of medication, external orthoses, therapeutic exercises, and manual therapy to alleviate symptoms and enhance the overall function of the SIJ (1).
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='External Orthoses' subheader='Figure 1. (Example of an SIJ belt)' />
            <CardMedia
              component="img"
              image="/sij_belt.png"
              alt="SIJ Pain"
              className="ct-card-media"
            />
            <CardContent className="card-description">
              <p className='background-text'>
                <p className='underline text-header'>
                  SIJ Belts
                </p>
                <ul>
                  <li>
                    SIJ belts are often utilized to provide stability across the pelvic girdle to those with SIJ hypermobility, muscle weakness, or poor neuromotor control (2).
                  </li>
                  <li>
                    Appropriateness for this orthotic can be determined by obtaining a positive result for the active straight leg raise (ASLR) test (3).
                  </li>
                  <li>
                    Application of this belt with moderate tension was found to reduce pain as well as rectus femoris over-activity.
                  </li>
                  <li>
                    Application of this belt with maximal tension was found to improve gait cadence and velocity.
                  </li>
                </ul>
              </p>
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/hOlv1WtwjU0?si=_7gI6kubHM3RnxpE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <p className='background-text'>
                <p className='underline text-header'>ASLR test</p>
                To perform this test:
                <ol>
                  <li>
                    Begin with the patient lying supine
                  </li>
                  <li>
                    Instruct the patient to lift one foot off of the table straight up about 6 inches
                  </li>
                  <li>
                    If the patient does not report pain with movement, the test is negative and the test is complete. If the patient reports pain, the test is positive and must continue to the next part of the test.
                  </li>
                  <li>
                    For a positive result, the tester now provides a compressive force to bilateral ilia before asking the patient to lift their leg once again
                  </li>
                </ol>
                A positive test now indicates reduction or absence of pain with compression.
              </p>
            </CardContent>
            <CardHeader subheader='Figure 2. (Example of shoe lifts)' />
            <CardMedia
              component="img"
              image="/shoe_lift.png"
              alt="SIJ Pain"
              className="ct-card-media"
            />
            <CardContent className="card-description">
              <p className='background-text'>
                <p className='underline text-header'>
                  Shoe Lifts
                </p>
                Leg length discrepancies (LLD) are often associated with SIJ related back pain and can often cause malalignment throughout the body and muscle imbalances (5).  LLD can be true or apparent. A true LLD occurs when one leg measures to be shorter than the other due to an actual difference in bone length, while an apparent LLD occurs when the difference in length is due to a positional abnormality. As a result, a LLD can cause pelvic obliquity and cause higher loads of force to pass through the SIJ (2).  True LLD may benefit from a shoe lift as it can even out the length of the limbs and prevent otherwise biomechanic pain.
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='Exercise' subheader='Figure 3. (Exercises of the gluteus maximus strengthening program. A) Bilateral bridge, B) Unilateral bridge, C) Hip abduction in quadruped, D) Hip extension in prone (with knee flexed), E) Deadlift.)' />
            <CardMedia
              component="img"
              image="/exercise2.png"
              alt="SIJ Pain"
              className="ct-card-media"
            />
            <CardContent className="card-description">
              <p className='background-text'>
                Therapeutic exercise is important for addressing imbalances in SIJD, but there's no one-size-fits-all program. The diverse nature of SIJD presentations makes it challenging to create a standardized exercise routine. However, by focusing on balancing muscle length, strength, and proper motor control to improve force absorption through the pelvis, exercise prescription can be more tailored (2).
              </p>
              <p className='background-text'>
                Certain muscle groups attaching to the pelvis are commonly identified as potential contributors to tightness and weakness. While these muscles may not be the only muscles affected, they serve as a useful starting point for evaluating patient limitations (2).
                <br />
                The following are commonly tight muscles:
                <ul>
                  <li>
                    Iliopsoas
                  </li>
                  <li>
                    Rectus femoris
                  </li>
                  <li>
                    tensor fascia latae
                  </li>
                  <li>
                    Adductors
                  </li>
                  <li>
                    Quadratus lumborum
                  </li>
                  <li>
                    Latissimus dorsi
                  </li>
                  <li>
                    Obturator internus
                  </li>
                </ul>
                The following are commonly tight muscles:
                <ul>
                  <li>
                    Gluteus medius
                  </li>
                  <li>
                    Gluteus maximus
                  </li>
                  <li>
                    Lower abdominal
                  </li>
                  <li>
                    Hamstrings
                  </li>
                </ul>
              </p>
              <p className='background-text'>
                A treatment hypothesis for SIJD focuses primarily on addressing the gluteus maximus, particularly in cases where patients exhibit gait abnormalities. The theory states that muscular inefficiency in the glute max contributes to overactivation of the rectus femoris, exacerbating SIJD symptoms (6).
              </p>
              <p className='background-text'>
                An experiment based on this hypothesis was conducted and found that strengthening the gluteus maximus for 30 minutes, twice a week, over the course of 5 weeks was able to strengthen the muscle, reduce activation of the rectus femoris, and significantly reduce pain and disability (7).
              </p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title='Manual Therapy' />
            <CardContent className="card-description">
              <p className='background-text'>
                Given the intricate nature of pain origins in this condition, various hands-on techniques have been devised for treating SIJD.
              </p>
              <b className='background-text'>Mulligan’s Mobilization with Movement</b>
              <p className='background-text'>
                Mobilization with movement (MWM) integrates pain-free accessory glides with active movement to reinstate normal joint mechanics. These active movements may include forward flexion of the trunk, back extension, walking, or performing other functional movements (8).
              </p>
              <p className='background-text text-header'>
                Posterior MWM
              </p>
              <p className='background-text'>
                A Posterior MWM is performed with the tester placing one hand on the sacrum for stability then using the other hand to hold onto the patient’s ASIS. As the patient moves into an active movement, the tester pulls the innominate posteriorly on the sacrum and maintains the glide until the motion is completed.
              </p>
              <div className='video-container'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/1zcmd-CXsEc?si=zugIZ0G3fYScnjlG" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <p className='background-text text-header'>
                Anterior MWM
              </p>
              <p className='background-text'>
                An Anterior MWM is performed with the tester placing one hand on the pubic ramus for stability then using the other hand to hold onto the patient’s PSIS. As the patient moves into an active movement, the tester pulls the PSIS anteriorly on the sacrum and maintains the glide until the motion is completed.
              </p>
              <b className='background-text'>Muscle Energy Techniques (METs)</b>
              <p className='background-text'>
                Additionally, Muscle Energy Techniques (METs), another manual therapy method, prioritizes active patient involvement by incorporating muscle contractions and relaxation to enhance joint function. Utilizing this technique depends greatly on the disposition of the pelvic innominates and determining if which side is more posteriorly or anteriorly positioned.
              </p>
              <p className='background-text text-header'>
                Determining innominate rotation
              </p>
            </CardContent>
            <CardHeader subtitle='Figure 4. (Posterior and anterior rotated innominates)' />
            <CardMedia
              component="img"
              image="/rotated_innominates.png"
              alt="SIJ Pain"
              className="ct-card-media"
            />
            <CardContent className="card-description">
              <p className='background-text'>
                In standing, a clinician can palpate the patient’s bilateral ASIS and PSIS and compare the levels at which they line up. These landmarks should typically be located at the same level. An anterior rotation would indicate one innominate’s ASIS to be lower than the other, and the PSIS to be higher than the other. The opposite would relatively prove true for a posteriorly rotated innominate and for this reason.
              </p>
              <div className='video-container'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/O6p-sQO_OHw?si=iueJ0aEAxRC6ZfUx" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <p className='background-text text-header'>
                To perform the MET (9)
                <ol>
                  <li>
                    Begin with the patient lying supine
                  </li>
                  <li>
                    Have the patient bend the knee with their foot flat on the floor, on the side of the anteriorly rotated innominate, and provide a downward force through their foot (patient may also perform a single leg bridge if able)
                  </li>
                  <li>
                    At the same time, have the patient flex the hip and knee of the opposite side to 90° and provide an isometric force against flexion
                  </li>
                  <li>
                    Hold position for ~5 seconds
                  </li>
                  <li>
                    Relax and repeat 5 times
                  </li>
                </ol>
              </p>
            </CardContent>
          </Card>
        </div>
        <div className='ref-area'>
          <h3 className='ref-title'>
            References:
          </h3>
          <p>
            <ol>
              <li>
                Chen LY, Liang HD, Qin QN, et al. Sacroiliac joint fusion VS conservative management for chronic low back pain attributed to the sacroiliac joint: A protocol for systematic review and meta analysis. Medicine (Baltimore). 2020;99(46):e23223. doi:10.1097/MD.0000000000023223
              </li>
              <li>
                Prather H, Bonnette M, Hunt D. Nonoperative Treatment Options for Patients With Sacroiliac Joint Pain. Int J Spine Surg. 2020;14(Suppl 1):35-40. Published 2020 Feb 10. doi:10.14444/6082
              </li>
              <li>
                Mens JM, Vleeming A, Snijders CJ, Stam HJ, Ginai AZ. The active straight leg raising test and mobility of the pelvic joints. Eur Spine J. 1999;8(6):468-473. doi:10.1007/s005860050206
              </li>
              <li>
                Hammer N, Möbius R, Schleifenbaum S, et al. Pelvic Belt Effects on Health Outcomes and Functional Parameters of Patients with Sacroiliac Joint Pain [published correction appears in PLoS One. 2015;10(10):e0140090]. PLoS One. 2015;10(8):e0136375. Published 2015 Aug 25. doi:10.1371/journal.pone.0136375
              </li>
              <li>
                Kiapour A, Abdelgawad AA, Goel VK, Souccar A, Terai T, Ebraheim NA. Relationship between limb length discrepancy and load distribution across the sacroiliac joint—a finite element study. Journal of Orthopaedic Research. 2012;30(10):1577-1580. doi:10.1002/jor.22119
              </li>
              <li>
                Hossain M, Nokes LD. A model of dynamic sacro-iliac joint instability from malrecruitment of gluteus maximus and biceps femoris muscles resulting in low back pain. Med Hypotheses. 2005;65(2):278-281. doi:10.1016/j.mehy.2005.02.035
              </li>
              <li>
                Aurélio M, de Freitas DG, Kasawara KT, Martin RL, Fukuda TY. Strengthening the gluteus maximus in subjects with sacroiliac dysfunction. International Journal of Sports Physical Therapy. 2018;13(1):114-120. doi:10.26603/ijspt20180114
              </li>
              <li>
                Kawishwar SS, Samal S, Ramteke S. To study the effect of Mulligan Mobilization versus conventional therapy in sacroiliac joint pain. Indian Journal of Public Health Research &amp; Development. Published online May 24, 2020. doi:10.37506/ijphrd.v11i5.9365
              </li>
              <li>
                Thomas E, Cavallaro AR, Mani D, Bianco A, Palma A. The efficacy of muscle energy techniques in symptomatic and asymptomatic subjects: a systematic review. Chiropr Man Therap. 2019;27:35. Published 2019 Aug 27. doi:10.1186/s12998-019-0258-7
              </li>
            </ol>
          </p>
        </div>
      </div>
      <PageNav page='Non-Invasive Treatment' />
    </AnimatedContent>
  )
}
