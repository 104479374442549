import { AnimatePresence } from 'framer-motion'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import React from 'react'
import { Home } from '../../pages/home/home'
import { NotFound } from '../../pages/not-found/notFound'
import { Background } from '../../pages/background/background'
import { Diagnosis } from '../../pages/diagnosis/diagnosis'
import { AboutUs } from '../../pages/about-us/about-us'
import { ConservativeTreatment } from '../../pages/conservative-treatment/conservative-treatment'
import { NonConservativeTreatment } from '../../pages/non-conservative-treatment/non-conservative-treatment'
import { Pathology } from '../../pages/pathology/pathology'

export const AnimatedRoutes = () => {
    const location = useLocation()
    return (
        <AnimatePresence mode="wait" initial={true}>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route path="/404" element={<NotFound />}></Route>
                <Route path="/home" element={<Home />}></Route>
                {/* <Route path="/about-us" element={<AboutUs />}></Route> */}
                <Route path="/anatomy" element={<Background />}></Route>
                <Route path="/pathology" element={<Pathology />}></Route>
                <Route path="/diagnosis" element={<Diagnosis />}></Route>
                <Route path="/non-invasive-treatment" element={<ConservativeTreatment />}></Route>
                <Route path="/invasive-treatment" element={<NonConservativeTreatment />}></Route>
            </Routes>
        </AnimatePresence>
    )
}
