import { Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import './non-conservative-treatment.css'
import React from 'react'
import AnimatedContent from '../../components/animated-content/animated-content'
import { PageNav } from '../../components/page-nav/pageNav'

export const NonConservativeTreatment = () => {
  return (
    <AnimatedContent>
      <div className='page-content'>
        <div className='card-container'>
          <Card className='card' variant='outlined'>
            <CardHeader title='Invasive Treatments' subheader='Figure 1. (Falowski et al.’s algorithmic approach to SIJ pain)' />
            <CardMedia
              component="img"
              image="/dysfunction_algorithm.png"
              alt="SIJ Pain"
              className="nct-card-media"
            />
            <CardContent className="card-description">
              <p className="background-text">When conservative treatments prove ineffective in alleviating persistent SIJ symptoms and pharmaceutical interventions no longer provide pain relief, the consideration of invasive procedures becomes a crucial aspect of comprehensive management. Falowski et al. (1) suggested an algorithm on managing SIJD through the continuum of care that states once conservative treatment has been exhausted, the patient can trial and be considered for a number of more invasive treatment options. This section is intended for patient education purposes and does not substitute for medical advice.</p>
            </CardContent>
          </Card>
          <Card className='card' variant='outlined'>
            <CardHeader title="Therapeutic SIJ Injections" />
            <CardContent className="card-description">
              <p className="background-text">Similar to the diagnostic SIJ injection, therapeutic injections also inject an analgesic directly into the sacroiliac joint space with imaging in order to decrease inflammation and numb the area from pain (2). Afterwards, the patient will be monitored for the length of effectiveness of the injection. If the patient experiences greater than 50-70% of pain relief for greater than 3 months, the injection is considered effective and the patient is eligible for repeated injections to treat the dysfunction. If the patient does not meet this criteria, they may be a candidate for operation.</p>
            </CardContent>
            <CardHeader title="Minimally Invasive SIJ Fusion" subheader='Figure 2. (Example of SIJ fusion with screw placement)' />
            <CardMedia
              component="img"
              image="/sij_fusion.png"
              alt="SIJ Pain"
              className="nct-card-media"
            />
            <CardContent className="card-description">
              <p className="background-text">Surgical stabilization or fusion of the SI joint should only be considered if the patient has persistent moderate to severe pain, functional impairment, and failure of non-operative intervention (therapy and injections) for a minimum of six months (3). Fusion can be done through a posterior or lateral approach, involving screws or percutaneous cortical allografts to secure fixation. Positive results have been found with SIJ fusion where patients often report at least a 50% improvement in SIJD symptoms within 12 months post operation (4).</p>
            </CardContent>
            <CardHeader title="Radiofrequency Ablation (RFA)" subheader="Figure 3. (Radiofrequency Ablation)" />
            <CardMedia
              component="img"
              image="/rfa.png"
              alt="SIJ Pain"
              className="nct-card-media"
            />
            <CardContent className='card-description'>
              <p className='background-text'>When all other treatments for SIJ pain have proven ineffective, or in cases where the patient does not qualify for surgical fusion, RFA emerges as another valuable alternative. This minimally invasive procedure involves the use of radiofrequency energy to interrupt pain signals transmitted by the nerves surrounding the sacroiliac joint, thereby alleviating chronic pain, disability, and in some cases, reducing opioid use (5). RFA typically targets the lateral branches of the primary dorsal rami spanning from L5 to S2 and has been found to be successful in pain relief for at least 6 months for over 60% of cases. Ablation from the lateral branches of the primary dorsal rami may not benefit all SIJ pain as it does not address pain originating from the ventral aspect of the joint. Ablation from the lateral branches of the primary dorsal rami may not benefit all SIJ pain as it does not address pain originating from the ventral aspect of the joint, meaning this procedure may not be successful in every case of SIJD (6).</p>
            </CardContent>
          </Card>
        </div>
        <div className='ref-area'>
          <h3 className='ref-title'>
            References:
          </h3>
          <p>
            <ol>
              <li>
              Falowski S, Sayed D, Pope J, et al. A review and algorithm in the diagnosis and treatment of sacroiliac joint pain. Journal of Pain Research. 2020;Volume 13:3337-3348. doi:10.2147/jpr.s279390 
              </li>
              <li>
              Wendling D. Local sacroiliac injections in the treatment of spondyloarthritis. what is the evidence? Joint Bone Spine. 2020;87(3):209-213. doi:10.1016/j.jbspin.2019.06.003 
              </li>
              <li>
              Rosenberg JM, Quint DJ, de Rosayro AM. Computerized tomographic localization of clinically-guided sacroiliac joint injections. Clin J Pain. 2000;16(1):18–212000. doi:10.1097/00002508-200003000-00004
              </li>
              <li>
              Raikar SV, Nilles-Melchert T, Patil AA, Crum W, Pandey D. Posterior oblique approach for sacroiliac joint fusion. Cureus. Published online January 8, 2023. doi:10.7759/cureus.33502 
              </li>
              <li>
              35.  Tinnirello A. Reduction of opioid intake after cooled radiofrequency denervation for sacroiliac joint pain: A retrospective evaluation up to 1 Year. The Korean Journal of Pain. 2020;33(2):183-191. doi:10.3344/kjp.2020.33.2.183
              </li>
              <li>
              Cox RC, Fortin JD. The anatomy of the lateral branches of the sacral dorsal rami: implications for radiofrequency ablation. Pain Physician. 2014;17(5):459-464.
              </li>
            </ol>
          </p>
        </div>
      </div>
      <PageNav page='Invasive Treatment' />
    </AnimatedContent>
  )
}
