import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface PageNavProps {
    page: string
}

export const PageNav = ({page}: PageNavProps) => {
    const navigate = useNavigate()
    const navDict = {
        'Home': '/home',
        'Anatomy': '/anatomy',
        'Pathology': '/pathology',
        'Diagnosis': '/diagnosis',
        'Non-Invasive Treatment': '/non-invasive-treatment',
        'Invasive Treatment': '/invasive-treatment'
    }

    const next = (): string => {
        let keys = Object.keys(navDict)
        for (let i = 0; i < keys.length; i++) {
            if (page == keys[i]) {
                if (i + 1 >= keys.length) {
                    return keys[0]
                } else {
                    return keys[i + 1]
                }
            }
        }
        return keys[0]
    }

    const prev = (): string => {
        let keys = Object.keys(navDict)
        for (let i = 0; i < keys.length; i++) {
            if (page == keys[i]) {
                if (i - 1 <= -1) {
                    return keys[0]
                } else {
                    return keys[i - 1]
                }
            }
        }
        return keys[0]
    }

    return (
        <div className='page-nav'>
            <div className='nav-btn no-height'>
                <Button onClick={() => navigate(navDict[prev() as keyof typeof navDict])} color='secondary' startIcon={<ArrowBackIcon />} variant="contained">{prev()}</Button>
            </div>
            <div className='nav-btn'>
                <Button onClick={() => navigate(navDict[next() as keyof typeof navDict])} color='secondary' endIcon={<ArrowForwardIcon />} variant="contained">{next()}</Button>
            </div>
        </div>
    )
}
