import React from 'react';
import './App.css';
import { AnimatedRoutes } from './components/animated-routes/animatedRoutes';
import { NavBar } from './components/nav-bar/navBar';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, styled } from '@mui/material';
import { purple } from '@mui/material/colors';
import ScrollToTop from './components/scroll-to-top/scrollToTop';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3e5075',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})


function App() {
  return (
    <div className="background">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavBar />
          <ScrollToTop />
          <div className='page-area'>
            <AnimatedRoutes />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
