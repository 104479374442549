import { Box, Grid } from '@mui/material'
import './home.css'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import AnimatedContent from '../../components/animated-content/animated-content';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';


export const Home = () => {
    const navigate = useNavigate();
    return (
        <AnimatedContent>
            <div className='home-page-content'>
                <div className='header-text'>
                    <h2 className='header-title'>Welcome to SIJ Solutions!</h2>
                    <Box className='home-description'>
                        <p>This site serves as a reference for clinicians to utilize when suspecting a case of sacroiliac joint dysfunction (SIJD).</p>
                        <p>Click the links below to learn more about how to navigate this dysfunction!</p>
                    </Box>
                </div>
                <Grid container spacing={1} className='grid-container'>
                    <Grid item xs={12} sm={6} md={2.4} className='grid-item'>
                        <button onClick={() => navigate('/anatomy')} className='item-container'>
                            <Box className='item-icon-container'>
                                <span className="material-symbols-outlined item-icon">
                                    skeleton
                                </span>
                                {/* <HistoryEduIcon className='item-icon' /> */}
                            </Box>
                            <p className='item-text'>Anatomy</p>
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} className='grid-item'>
                        <button onClick={() => navigate('/pathology')} className='item-container'>
                            <Box className='item-icon-container'>
                                <HistoryEduIcon className='item-icon' />
                            </Box>
                            <p className='item-text'>Pathology</p>
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} className='grid-item'>
                        <button onClick={() => navigate('/diagnosis')} className='item-container'>
                            <Box className='item-icon-container'>
                                <TroubleshootIcon className='item-icon' />
                            </Box>
                            <p className='item-text'>Diagnosis</p>
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} className='grid-item'>
                        <button onClick={() => navigate('/non-invasive-treatment')} className='item-container'>
                            <Box className='item-icon-container'>
                                <FitnessCenterIcon className='item-icon' />
                            </Box>
                            <p className='item-text'>Non-Invasive Treatments</p>
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} className='grid-item'>
                        <button onClick={() => navigate('/invasive-treatment')} className='item-container'>
                            <Box className='item-icon-container'>
                                <VaccinesIcon className='item-icon' />
                            </Box>
                            <p className='item-text'>Invasive Treatments</p>
                        </button>
                    </Grid>
                </Grid>
                <Box className='description'>
                    <h2 className='sijd-title'>
                        What is SIJD?
                    </h2>
                    <p className='sijd-desc'>
                        Sacroiliac Joint Dysfunction (SIJD) is a musculoskeletal condition characterized by pain and dysfunction in the sacroiliac joint. The sacroiliac joint (SIJ) is located in the pelvis, connecting the sacrum (the triangular bone at the base of the spine) to the ilium (part of the pelvic bone). This joint plays a fundamental role in supporting the weight of the upper body and transferring forces between the spine and the lower body during various activities such as walking, running, and standing. Dysfunction or injury to the sacroiliac joint can lead to significant pain and discomfort in the lower back, buttocks, and legs, impacting mobility and quality of life. Understanding the anatomy and function of the SIJ is essential for diagnosing and effectively managing conditions related to this complex joint.
                    </p>
                </Box>
            </div >
        </AnimatedContent>
    )
}
